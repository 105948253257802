<template>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <label for="">Estatus del Trabajador *</label>
                <div class="row mt-2">
                    <div class="col-lg-4">
                        <div class="form-check form-check-inline RadioOperando">
                            <input class="form-check-input" type="radio" id="Puntual" v-model="CheckTrabajador.Estatus" value="Puntual">
                            <!-- <span class="RadioPuntualSpan"></span> -->
                            <label class="form-check-label" for="Puntual">Puntual</label>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-check form-check-inline RadioObservacion">
                            <input  class="form-check-input" type="radio" id="Retardo" v-model="CheckTrabajador.Estatus" value="Retardo">
                            <label class="form-check-label" for="Retardo">Retardo</label>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-check form-check-inline RadioFueraServicio">
                            <input class="form-check-input" type="radio" id="Falta" v-model="CheckTrabajador.Estatus" value="Falta">
                            <label class="form-check-label" for="Falta">Falta</label>
                        </div>
                    </div>
                </div>
                <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.Estatus" :Mensaje="'Campo obligatorio'"></Cvalidation></label>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <label for="">Motivo del Cambio *</label>
                <textarea v-model="CheckTrabajador.MotivoEstatus" rows="5" cols="10" class="form-control"></textarea>
                <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.MotivoEstatus" :Mensaje="'Campo obligatorio'"></Cvalidation></label>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:"Capacitacion",
    props:["poBtnSave","pIdTrabajador"],
    data() {
        return {
            Modal:true,
            FormName:'Estatus',
            EmitSeccion:this.poBtnSave.EmitSeccion,
            CheckTrabajador:{
                IdCheck:0,
                IdTrabajador:0,
                TipoCheck:'',
                Latitud:0,
                Longitud:0,
                Imagen:'',
                ImagenCheckOut:'',
                Comentario:'',
                Estatus:'',
                FechaCheckOut:'',
                RegEstatus:'',
                FechaSite:'',
                ComentarioSite:'',
                LatitudSite:0,
                LongitudSite:0,
                ComentarioCheckOut:'',
                LatitudOut:0,
                LongitudOut:0,
                HoraExtra:0,
                CantidadHoraExtra:0,
                EstatusAnterior:'',
                MotivoEstatus:''
            },
            errorvalidacion:[]
        }
    },
    methods:{
        async Guardar(){
            let formData=new FormData();
            formData.set('IdCheck',this.CheckTrabajador.IdCheck);
            formData.set('Estatus',this.CheckTrabajador.Estatus);
            formData.set('EstatusAnterior',this.CheckTrabajador.EstatusAnterior);
            formData.set('MotivoEstatus',this.CheckTrabajador.MotivoEstatus);
            await this.$http.post('checkincambiarestatus/post',formData).then((res)=>{
                this.poBtnSave.disableBtn=false; 
                this.poBtnSave.toast=1;
                $("#Estatus").modal("hide");
                this.bus.$emit('ListaPrincipal');
            }).catch((err)=>{
                this.errorvalidacion=err.response.data.message.errores;
                this.poBtnSave.disableBtn=false;
                this.poBtnSave.toast=2;
            });
        },
        Limpiar(){
            this.CheckTrabajador={
                IdCheck:0,
                IdTrabajador:0,
                TipoCheck:'',
                Latitud:0,
                Longitud:0,
                Imagen:'',
                ImagenCheckOut:'',
                Comentario:'',
                Estatus:'',
                FechaCheckOut:'',
                RegEstatus:'',
                FechaSite:'',
                ComentarioSite:'',
                LatitudSite:0,
                LongitudSite:0,
                ComentarioCheckOut:'',
                LatitudOut:0,
                LongitudOut:0,
                HoraExtra:0,
                CantidadHoraExtra:0,
                EstatusAnterior:'',
                MotivoEstatus:''
            }
            this.errorvalidacion=[];
        },
        async Recuperar(){
            await this.$http.get('checkin/recovery',{
                params:{
                    IdCheck:this.CheckTrabajador.IdCheck
                }
            }).then((res)=>{
                this.CheckTrabajador=res.data.data.checktrabajador;
                this.CheckTrabajador.EstatusAnterior=res.data.data.checktrabajador.Estatus;
            });
        }
    },
    created(){
        this.bus.$off("NewModal_"+this.EmitSeccion);
        this.bus.$on("NewModal_"+this.EmitSeccion,(data,Id)=>{
            this.poBtnSave.disableBtn=false;
            this.bus.$off("Save_"+this.EmitSeccion);
            this.bus.$on("Save_"+this.EmitSeccion, () => {
				this.Guardar();
			});
            this.Limpiar();
            if (Id > 0) {
                this.CheckTrabajador.IdCheck = Id;
                this.Recuperar();
			}
        });
    }
}
</script>

<style>

</style>