var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-lg-6"},[_c('label',{attrs:{"for":""}},[_vm._v("Fecha Inicio *")]),_c('v-date-picker',{attrs:{"mode":_vm.typeCalendar,"popover":{
						placement: 'bottom',
						visibility: 'click'
					},"input-props":{
						class: 'form-control cal-black',
						style: 'cursor:pointer;'
					}},model:{value:(_vm.Vacacion.FechaInicio),callback:function ($$v) {_vm.$set(_vm.Vacacion, "FechaInicio", $$v)},expression:"Vacacion.FechaInicio"}}),_c('label',{staticStyle:{"color":"red"},attrs:{"id":"lblmsuser"}},[(this.errorvalidacion.FechaInicio)?_c('Cvalidation',{attrs:{"Mensaje":this.errorvalidacion.FechaInicio[0]}}):_vm._e()],1)],1),_c('div',{staticClass:"col-lg-6"},[_c('label',{attrs:{"for":""}},[_vm._v("Fecha Fin *")]),_c('v-date-picker',{attrs:{"mode":_vm.typeCalendar,"popover":{
						placement: 'bottom',
						visibility: 'click'
					},"input-props":{
						class: 'form-control cal-black',
						style: 'cursor:pointer;'
					}},model:{value:(_vm.Vacacion.FechaFin),callback:function ($$v) {_vm.$set(_vm.Vacacion, "FechaFin", $$v)},expression:"Vacacion.FechaFin"}})],1)]),_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-lg-12"},[_c('label',{attrs:{"for":""}},[_vm._v("Archivo")]),_c('div',{staticClass:"custom-file-input-image"},[_c('input',{ref:"file",staticClass:"custom-file-input",attrs:{"type":"file","accept":"application/pdf","id":"validatedCustomFile","required":""},on:{"change":function($event){return _vm.SubirArchivo()}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.Archivo),expression:"Archivo"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.Archivo)},on:{"input":function($event){if($event.target.composing){ return; }_vm.Archivo=$event.target.value}}}),_vm._m(0)])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('label',{attrs:{"for":""}},[_vm._v("Comentario")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.Vacacion.Comentario),expression:"Vacacion.Comentario"}],staticClass:"form-control",attrs:{"cols":"10","rows":"10"},domProps:{"value":(_vm.Vacacion.Comentario)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.Vacacion, "Comentario", $event.target.value)}}})])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{attrs:{"type":"button"}},[_c('i',{staticClass:"fas fa-paperclip"})])}]

export { render, staticRenderFns }